import { formatPeriod } from '@capturi/filters'
import { Button, ButtonProps } from '@capturi/ui-components'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MdDateRange } from 'react-icons/md'

import { usePeriodContext } from '../contexts/PeriodContext'

type PeriodDefButtonProps = Omit<ButtonProps, 'children'>

const PeriodDefButton: React.FC<PeriodDefButtonProps> = React.forwardRef<
  HTMLButtonElement,
  PeriodDefButtonProps
>(function DateButton(props, ref) {
  const { periodDef } = usePeriodContext()
  return (
    <Button
      ref={ref}
      size="sm"
      borderRadius="md"
      leftIcon={<MdDateRange />}
      {...props}
    >
      {periodDef ? formatPeriod(periodDef) : <Trans>Select date...</Trans>}
    </Button>
  )
})

export default PeriodDefButton
