import React from 'react'

type AnalysisContextType = {
  useLiveData: boolean
  sourceId?: string
}

const AnalysisContext = React.createContext<AnalysisContextType>({
  useLiveData: false,
  sourceId: undefined,
})

export default AnalysisContext
