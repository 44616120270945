import { Speaker } from '@capturi/core'
import { Role } from './model/domain'

export function relativeDifference(a: number, b: number): number {
  return (a - b) / b
}

export function roleToSpeaker(role: Role): Speaker | null {
  switch (role) {
    case 'Agent':
      return Speaker.Employee
    case 'Customer':
      return Speaker.Customer
    case 'All':
      return Speaker.All
  }
}
