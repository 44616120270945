import { useAPI } from '@capturi/api-utils'
import { TopicPhrasesResponseModel } from 'features/aiTopics/model/api'
import { Role, TopicPhrase } from 'features/aiTopics/model/domain'
import { useMemo } from 'react'
import { SWRConfiguration, SWRResponse } from 'swr'

import { trendsAPI } from './api'

type Options = {
  useLiveData?: boolean
  role?: Role
  period?: string
}

type UseTopicPhrases = (
  sourceId: string | undefined,
  topicIndex: number,
  options?: Options,
  swrConfig?: SWRConfiguration,
) => {
  phrases: TopicPhrase[]
  isLoading: boolean
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  mutate: SWRResponse<TopicPhrasesResponseModel, any>['mutate']
}

const useTopicPhrases: UseTopicPhrases = (
  sourceId,
  topicIndex,
  options = {},
  swrConfig = {},
) => {
  const { data, error, mutate } = useAPI<TopicPhrasesResponseModel>(
    () => {
      if (sourceId == null) {
        return null
      }
      const { useLiveData, role, period } = options
      if (useLiveData) {
        return trendsAPI.getLiveTrendPhrases(sourceId, topicIndex)
      }
      if (role && period != null) {
        return trendsAPI.getTrendPhrases(sourceId, topicIndex, role, period)
      }
      return null
    },
    {
      suspense: true,
      revalidateOnFocus: false,
      ...swrConfig,
    },
  )
  return useMemo(() => {
    const phrases = data?.phrases ?? []
    return {
      phrases,
      isLoading: !(data || error),
      mutate,
    }
  }, [data, error, mutate])
}

export default useTopicPhrases
