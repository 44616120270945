import { Description, ListSkeleton } from '@capturi/ui-components'
import { Box, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { Topic } from 'features/aiTopics'
import React from 'react'

import AnalysisContext from '../contexts/AnalysisContext'
import { usePeriodContext } from '../contexts/PeriodContext'
import { useRoleContext } from '../contexts/RoleContext'
import { useTrendsOnTrendsContext } from '../contexts/TrendsOnTrendsContext'
import { LiveTrendsResponse } from '../data/types'
import { PlayLiveSnippetsRouter } from '../drawer/routers'
import { useLogEvent } from '../events'
import useDrawerDisclosure from '../hooks/useDrawerDisclosure'
import DataTable from './DataTable'
import NoContent from './NoContent'
import TopicsWordCloud, { TopicsWordCloudType } from './TopicsWordCloud'

const LiveTrendsOnTrends: React.FC<{
  liveTrendsResponse: LiveTrendsResponse | undefined
  isLoading: boolean
}> = ({ liveTrendsResponse, isLoading }) => {
  const logEvent = useLogEvent()
  const { topicIndex, isOpen, onClose, onOpen } = useDrawerDisclosure()

  const handlePlaySnippets = React.useCallback(
    (topic: Topic): void => {
      onOpen(topic.index)
      logEvent('megadrawer-trends-on-trends-list--play-conversations')
    },
    [onOpen, logEvent],
  )

  const handleChangeWordCloudType = React.useCallback(
    (type: TopicsWordCloudType) => {
      logEvent('megadrawer--select-wordcloudtype', {
        wordCloudType: type,
      })
    },
    [logEvent],
  )

  const topics = liveTrendsResponse?.topics ?? []

  if (topics.length === 0 && !isLoading) {
    return <NoContent title={t`Not enough conversations to calculate trends`} />
  }

  return (
    <Box>
      {isLoading && (
        <Description as="div" fontSize="xl" textAlign="center">
          <Text>
            <Trans>Just a moment</Trans>
          </Text>
          <Text fontSize="0.8em">
            <Trans>Live trends can take several seconds to load.</Trans>
          </Text>
        </Description>
      )}

      <ListSkeleton isLoaded={!isLoading}>
        {topics.length > 0 && (
          <>
            <TopicsWordCloud
              initialWordCloud={TopicsWordCloudType.Conversations}
              topics={topics}
              onSelectTopic={handlePlaySnippets}
              onChangeTopicsWordCloudType={handleChangeWordCloudType}
            />
            <DataTable
              initalSorting="hits"
              topics={topics}
              onSelectTopic={handlePlaySnippets}
              onPlaySnippets={handlePlaySnippets}
            />
          </>
        )}
      </ListSkeleton>

      <PlayLiveSnippetsRouter
        topicIndex={topicIndex}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  )
}

export const TrendsOnTrendsContainer: React.FC<{
  segmentUid: string
  topicIndex: number
}> = ({ segmentUid, topicIndex }) => {
  const [role] = useRoleContext()
  const { period, periodDef } = usePeriodContext()
  const { data, fetch, isLoading } = useTrendsOnTrendsContext()

  React.useEffect(() => {
    fetch(
      segmentUid,
      topicIndex,
      role,
      role,
      periodDef.name,
      period.from,
      period.to,
    )
  }, [segmentUid, topicIndex, role, periodDef, fetch, period])

  const liveTopicsUid = data?.liveTopicsUid

  const analysisContext = React.useMemo(
    () => ({
      useLiveData: true,
      sourceId: liveTopicsUid,
    }),
    [liveTopicsUid],
  )
  return (
    <AnalysisContext.Provider value={analysisContext}>
      <LiveTrendsOnTrends liveTrendsResponse={data} isLoading={isLoading} />
    </AnalysisContext.Provider>
  )
}
