import {
  NamedPeriod,
  Period,
  PeriodDefinition,
  PeriodDefinitions,
} from '@capturi/filters'
import constate from 'constate'
import qs from 'query-string'
import React from 'react'
import useSyncSearchParam from 'router/useSyncSearchParam'

const QUERY_KEY = 'period'

function usePeriod({ defaultPeriod }: { defaultPeriod: PeriodDefinition }): {
  periodDef: PeriodDefinition
  setPeriodDef: (period: PeriodDefinition) => void
  period: Period
} {
  const [periodDef, setPeriodDef] = React.useState<PeriodDefinition>(() => {
    let { period: periodParam } = qs.parse(window.location.search)
    if (Array.isArray(periodParam)) {
      periodParam = periodParam[0]
    }
    return PeriodDefinitions[periodParam as NamedPeriod] ?? defaultPeriod
  })

  useSyncSearchParam(QUERY_KEY, periodDef.name)

  const period = React.useMemo(() => periodDef.create(new Date()), [periodDef])
  return {
    periodDef,
    setPeriodDef,
    period,
  }
}

const [PeriodProvider, usePeriodContext] = constate(usePeriod)

export { PeriodProvider, usePeriodContext }
