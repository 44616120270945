import constate from 'constate'
import { Role } from 'features/aiTopics'
import qs from 'query-string'
import React from 'react'
import useSyncSearchParam from 'router/useSyncSearchParam'

const QUERY_KEY = 'role'

const Roles: Role[] = ['Customer', 'Agent', 'All']

function useRole({
  defaultRole = 'Customer',
}: {
  defaultRole?: Role
}): [Role, (role: Role) => void] {
  const state = React.useState<Role>(() => {
    let { role: roleParam } = qs.parse(window.location.search)
    if (Array.isArray(roleParam)) {
      roleParam = roleParam[0]
    }
    return (
      Roles.includes((roleParam ?? '') as Role) ? roleParam : defaultRole
    ) as Role
  })

  useSyncSearchParam(QUERY_KEY, state[0])
  return state
}

const [RoleProvider, useRoleContext] = constate(useRole)

export { RoleProvider, useRoleContext }
