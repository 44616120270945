import { useAPI } from '@capturi/api-utils'
import { Role } from 'features/aiTopics/model/domain'
import { useMemo } from 'react'
import { SWRConfiguration, SWRResponse } from 'swr'

import { trendsAPI } from './api'
import { TrendsReportTopicDetailResponse } from './types'

type Options = {
  useLiveData?: boolean
  role?: Role
  period?: string
}

type UseTrendTopicDetails = (
  sourceId?: string,
  topicIndex?: number,
  options?: Options,
  swrConfig?: SWRConfiguration,
) => {
  trendsReportTopicDetail?: TrendsReportTopicDetailResponse
  isLoading: boolean
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  mutate: SWRResponse<TrendsReportTopicDetailResponse, any>['mutate']
}

const useTrendTopicDetails: UseTrendTopicDetails = (
  sourceId,
  topicIndex,
  options = {},
  swrConfig = {},
) => {
  const { useLiveData, role, period } = options
  const liveRequest =
    sourceId && topicIndex !== undefined
      ? trendsAPI.getLiveTrendTopicDetails(sourceId, topicIndex)
      : null
  const segmentRequest =
    sourceId && topicIndex !== undefined && role && period !== undefined
      ? trendsAPI.getTrendsReportTopicDetails(
          sourceId,
          topicIndex,
          role,
          period,
        )
      : null

  const request = useLiveData ? liveRequest : segmentRequest
  const { data, mutate, error } = useAPI<TrendsReportTopicDetailResponse>(
    () => request,
    {
      suspense: true,
      revalidateOnFocus: false,
      ...swrConfig,
    },
  )
  return useMemo(() => {
    return {
      trendsReportTopicDetail: data,
      isLoading: !(data || error),
      mutate,
    }
  }, [data, error, mutate])
}

export default useTrendTopicDetails
