import request, { RequestOptions, getErrorObject } from '@capturi/request'
import { Topic } from 'features/aiTopics/model/domain'
import noop from 'lodash/noop'
import React, { useMemo } from 'react'
import { useMountedState } from 'react-use'

import { LiveTrendsResponse } from './types'

const mapTrend = (trend: Topic): Topic => ({
  ...trend,
  name: trend.name.toLowerCase(),
})

function fetchData<T>(
  requestOptions: RequestOptions,
): [Promise<T>, () => void] {
  const controller = new AbortController()
  const { signal } = controller
  const promise = request<T>({
    ...requestOptions,
    signal,
  })
  return [promise, controller.abort.bind(controller)]
}

type UseLiveTrendsWithRequest = () => {
  fetchWithRequestOptions: (requestOptions: RequestOptions) => Promise<void>
  data: LiveTrendsResponse | undefined
  isLoading: boolean
  error: Error | undefined
}

const useLiveTrendsWithRequest: UseLiveTrendsWithRequest = () => {
  const isMounted = useMountedState()
  const [data, setData] = React.useState<LiveTrendsResponse | undefined>()
  const [error, setError] = React.useState<Error | undefined>()
  const [isLoading, setIsLoading] = React.useState(false)
  const abortRequestRef = React.useRef<() => void>(noop)

  const fetchWithRequestOptions = React.useCallback(
    async (requestOptions: RequestOptions): Promise<void> => {
      try {
        setIsLoading(true)
        setError(undefined)
        setData(undefined)
        abortRequestRef.current() // abort ongoing request
        const [promise, abort] = fetchData<LiveTrendsResponse>(requestOptions)
        abortRequestRef.current = abort
        const resp = await promise

        if (isMounted()) {
          resp.topics = resp.topics.map(mapTrend)
          setData(resp)
          setIsLoading(false)
        }
      } catch (e) {
        const error = await getErrorObject(e)
        if (error.name === 'AbortError') {
          // nevermind, on purpose
          return
        }
        if (isMounted()) {
          setError(error)
          setIsLoading(false)
        }
      }
    },
    [isMounted],
  )

  React.useEffect(() => {
    return () => {
      abortRequestRef.current()
    }
  }, [])

  return useMemo(() => {
    return {
      fetchWithRequestOptions,
      data,
      isLoading,
      error,
    }
  }, [fetchWithRequestOptions, data, isLoading, error])
}

export default useLiveTrendsWithRequest
