import { formatTime, useAudioContext } from '@capturi/audio'
import { Button, List, ListSkeleton } from '@capturi/ui-components'
import {
  Box,
  Drawer as ChakraDrawer,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import {
  PhraseOffset,
  Topic,
  TopicPhrase,
  roleToSpeaker,
} from 'features/aiTopics'
import React, { useState } from 'react'

import { useCurrentUser } from '@capturi/core'
import { useSingleUser } from '@capturi/stores'
import { i18n } from '@lingui/core'
import { useConversationDetailsDrawer } from 'components/ConversationDetailsDrawer'
import OptionalAudioPlayerActions from 'components/OptionalAudioPlayerActions'
import { PlayListItem } from 'components/PlayList'
import noop from 'lodash/noop'
import { CreateTrackerButton } from 'pages/TrackersPage/components/TrackerButtons'
import { MdLaunch } from 'react-icons/md'
import AnalysisContext from '../contexts/AnalysisContext'
import { usePeriodContext } from '../contexts/PeriodContext'
import { useRoleContext } from '../contexts/RoleContext'
import { useTopicConversations } from '../data/useTopicConversations'
import useTopicPhrases from '../data/useTopicPhrases'
import useTopics from '../data/useTopics'
import { useLogEvent } from '../events'
import { unformatPhrase } from '../utils'
import TopicPhrasePopoverSelect from './TopicPhrasePopoverSelect'

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

type PlaySnippetDrawerContainerProps = {
  topicIndex: number
  phraseIndex?: number | null
  showExamples?: boolean
  onNavigate?: (topicIndex: number, phraseIndex?: number) => void
  onClose?: () => void
}

const PlaySnippetDrawerContainer: React.FC<PlaySnippetDrawerContainerProps> = ({
  topicIndex,
  phraseIndex: phraseIndexProp,
  showExamples = true,
  onClose = noop,
  onNavigate,
}) => {
  const user = useCurrentUser()

  const [role] = useRoleContext()
  const { sourceId, useLiveData } = React.useContext(AnalysisContext)

  const { periodDef } = usePeriodContext()

  const requestOptions = {
    useLiveData,
    role,
    period: periodDef.name,
  }

  const { topics } = useTopics(sourceId, requestOptions, {
    dedupingInterval: 60000,
  })

  const { phrases, isLoading: isLoadingPhrases } = useTopicPhrases(
    sourceId,
    topicIndex,
    requestOptions,
  )

  // Derive selected topic from `topicIndex` in route path
  const selectedTopic = React.useMemo<Topic | undefined>(() => {
    if (!topics || topics.length === 0) return
    return topics.find((x) => x.index === topicIndex)
  }, [topics, topicIndex])

  // Derive selected phrase from `phraseIndex` in route path
  const selectedPhrase = React.useMemo<TopicPhrase | undefined>(() => {
    if (phrases.length === 0) return
    if (phrases.length === 1) return phrases[0]
    return phrases.find((x) => x.index === phraseIndexProp)
  }, [phrases, phraseIndexProp])

  const {
    pages,
    isLoading: isLoadingConversations,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetchingNextPage,
  } = useTopicConversations(
    sourceId,
    topicIndex,
    selectedPhrase?.index,
    requestOptions,
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  React.useEffect(() => {
    refetch()
  }, [phraseIndexProp, refetch])

  const onPhraseChange = (phrase: TopicPhrase | undefined): void => {
    onNavigate?.(topicIndex, phrase?.index)
  }

  if (selectedTopic === undefined) {
    return null
  }

  return (
    <ChakraDrawer isOpen onClose={onClose} placement="right" size="md">
      <DrawerOverlay zIndex={1400} />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader
          borderBottom="1px"
          borderBottomColor="gray.200"
          lineHeight={1.4}
          display="flex"
          alignItems="center"
          pr={10}
        >
          <Box flex="1" fontFamily="body">
            <Box fontSize="sm" color="textMuted">
              <Trans>Topic: {selectedTopic?.name}</Trans>
            </Box>
            <Box fontSize="1rem">
              {phrases.length === 0 ? (
                selectedTopic.name
              ) : phrases.length === 1 && selectedPhrase ? (
                selectedPhrase.name
              ) : (
                <Box fontSize="md" fontWeight="normal">
                  <TopicPhrasePopoverSelect
                    phrase={selectedPhrase}
                    phrases={phrases}
                    onPhraseChange={onPhraseChange}
                  >
                    {selectedPhrase == null
                      ? t`All variants`
                      : selectedPhrase.name}
                  </TopicPhrasePopoverSelect>
                </Box>
              )}
            </Box>
            {showExamples && (
              <Text fontSize="sm" color="textMuted" fontWeight="normal">
                <Trans>Ex.</Trans>:{' '}
                <em>
                  {unformatPhrase(
                    selectedPhrase == null
                      ? selectedTopic.displayName
                      : selectedPhrase.displayName,
                  )}
                </em>
              </Text>
            )}
          </Box>
        </DrawerHeader>
        <DrawerBody overflow="auto" p={0}>
          <ListSkeleton isLoaded={!isLoadingConversations}>
            <List disablePadding>
              {pages.map((p, i) => (
                <React.Fragment key={i}>
                  {p.map((c) => (
                    <ConversationListItem
                      key={c.conversationUid}
                      isPlaybackDisabled={!user.permissions.playback}
                      selectedPhrase={selectedPhrase}
                      {...c}
                      conversationHasAudio={c.conversationHasAudio}
                      conversationDuration={c.conversationDuration}
                    />
                  ))}
                </React.Fragment>
              ))}
            </List>
          </ListSkeleton>
          {!isLoadingConversations && (
            <Flex my={4} justify="center" w="100%">
              <Button
                onClick={fetchNextPage}
                isDisabled={!hasNextPage || isFetchingNextPage}
                isLoading={isFetchingNextPage}
              >
                {!hasNextPage ? (
                  <Trans>No more conversations</Trans>
                ) : (
                  <Trans>Load more</Trans>
                )}
              </Button>
            </Flex>
          )}
        </DrawerBody>
        {user.isAdmin && (
          <DrawerFooter
            zIndex={0}
            boxShadow={'0 -4px 8px -4px rgba(0,0,0,0.2)'}
          >
            <CreateTrackerButton
              size="sm"
              width="full"
              isDisabled={isLoadingPhrases}
              initialData={() => {
                return {
                  name: selectedTopic?.name,
                  speakerId: roleToSpeaker(role) ?? undefined,
                  words: selectedPhrase
                    ? [selectedPhrase.name]
                    : phrases.map((x) => x.name),
                }
              }}
            >
              <Trans>Add as tracker</Trans>
            </CreateTrackerButton>
          </DrawerFooter>
        )}
      </DrawerContent>
    </ChakraDrawer>
  )
}

const ConversationListItem: React.FC<{
  conversationUid: string
  conversationCustomer: string
  conversationHasAudio: boolean
  conversationDateTime: Date
  conversationUserUid: string
  conversationDuration: number
  phrases?: PhraseOffset[]
  offsets?: number[]
  isPlaybackDisabled: boolean
  selectedPhrase?: TopicPhrase
}> = ({
  conversationUid,
  conversationCustomer,
  conversationHasAudio,
  conversationDateTime,
  conversationUserUid,
  conversationDuration,
  phrases = [],
  offsets = [],
  isPlaybackDisabled = false,
  selectedPhrase,
}) => {
  const logEvent = useLogEvent()
  const { name } = useSingleUser(conversationUserUid)

  const { getTime, pause, playbackContext } = useAudioContext()
  const [activeSnippet, setActiveSnippet] = useState<string>('')

  const phrasesAndOffsets = React.useMemo(() => {
    if (phrases.length > 0) {
      return phrases
    }
    return offsets.map((o) => ({
      phrase: selectedPhrase?.name,
      displayName: selectedPhrase?.displayName,
      offset: o,
    }))
  }, [phrases, offsets, selectedPhrase])

  const openConversationDetailsDrawer = useConversationDetailsDrawer()
  const onGoToConversation = (uid: string): void => {
    React.startTransition(() => {
      let initialAudioTimestamp: number | undefined
      if (playbackContext?.conversationUid === uid) {
        initialAudioTimestamp = getTime()
        pause()
      }
      openConversationDetailsDrawer({
        uid,
        initialAudioTimestamp,
      })
    })
  }

  return (
    <Box
      key={conversationUserUid}
      border={
        playbackContext?.conversationUid === activeSnippet
          ? '1px solid'
          : 'none'
      }
      borderColor={
        playbackContext?.conversationUid === activeSnippet
          ? 'primary.500'
          : 'none'
      }
      mb="24px"
      boxShadow="md"
      borderBottomRadius="8px"
      borderTopRadius="2px"
    >
      <Flex
        pl={4}
        pr={2}
        py={2}
        bg="gray.200"
        borderBottom="1px solid"
        borderColor="gray.600"
        borderTopRadius="2px"
      >
        <Box flex="1">
          <Text fontSize="md" fontWeight="medium">
            {conversationCustomer}
          </Text>
          <Flex fontSize="sm" color="gray.600" align="center">
            <Text as="span" flexShrink={0}>
              {i18n.date(conversationDateTime, dateFormat)}
            </Text>
            <Divider orientation="vertical" mx={2} minH={3} />
            <Text flex={1}>{name}</Text>
          </Flex>
        </Box>
        <IconButton
          aria-label={t`Go to conversation`}
          title={t`Go to conversation`}
          variant="ghost"
          size="xs"
          icon={<MdLaunch />}
          isRound
          onClick={() => onGoToConversation(conversationUid)}
        />
      </Flex>
      <List disablePadding>
        {phrasesAndOffsets
          .sort((a, b) => a.offset - b.offset)
          .map((phrase, i) => (
            <PlayListItem
              key={i}
              hasAudio={conversationHasAudio}
              timestamp={phrase.offset}
              rollbackSeconds={3}
              context={{ conversationUid, phrase: phrase.phrase }}
              onPlay={() => {
                logEvent('play-phrase-audio--clicked', {
                  date: conversationDateTime.toUTCString(),
                  uid: conversationUid,
                })
                setActiveSnippet(conversationUid)
              }}
              isDisabled={isPlaybackDisabled}
              showAudioPlayer
              additionalActions={(props) => (
                <OptionalAudioPlayerActions
                  {...props}
                  duration={conversationDuration}
                  conversationUid={conversationUid}
                />
              )}
            >
              <Flex align="center" justify="space-between" flex={1}>
                <Text>{phrase.phrase}</Text>
                <Text flex={0} ml={4} color="gray.600" fontSize="sm">
                  {formatTime(phrase.offset)}
                </Text>
              </Flex>
            </PlayListItem>
          ))}
      </List>
    </Box>
  )
}

export default function DrawerContainerWrapper(
  props: PlaySnippetDrawerContainerProps,
): React.ReactElement {
  return <PlaySnippetDrawerContainer {...props} />
}
