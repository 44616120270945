import { useDisclosure } from '@chakra-ui/react'
import React from 'react'

const useDrawerDisclosure = (): {
  topicIndex: number | undefined
  isOpen: boolean
  onClose: () => void
  onOpen: (topicIndex: number) => void
} => {
  const [topicIndex, setTopicIndex] = React.useState<number | undefined>()
  const { isOpen, onClose, onOpen } = useDisclosure()
  return {
    topicIndex,
    isOpen,
    onClose: React.useCallback(() => {
      onClose()
      setTopicIndex(undefined)
    }, [onClose]),
    onOpen: React.useCallback(
      (topicIndex: number) => {
        setTopicIndex(topicIndex)
        onOpen()
      },
      [onOpen],
    ),
  }
}

export default useDrawerDisclosure
