import { PageHeading } from '@capturi/ui-components'
import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import { Topic } from 'features/aiTopics'
import capitalize from 'lodash/capitalize'
import noop from 'lodash/noop'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import AnalysisContext from '../contexts/AnalysisContext'
import { usePeriodContext } from '../contexts/PeriodContext'
import { useRoleContext } from '../contexts/RoleContext'
import useTopics from '../data/useTopics'
import useTrendTopicDetails from '../data/useTrendTopicDetails'
import { useLogEvent } from '../events'
import TopicDetailsGrid from './TopicDetailsGrid'
import { PlayTrendsSnippetsRouter } from './routers'

type TrendMegaDrawerContainerProps = {
  topicIndex: number
  onNavigate?: (
    topicIndex?: number,
    phraseIndex?: number,
    conversations?: boolean,
  ) => void
  onClose?: () => void
}

const TrendMegaDrawerContainer: React.FC<TrendMegaDrawerContainerProps> = ({
  topicIndex,
  onClose = noop,
  onNavigate,
}) => {
  const [role] = useRoleContext()
  const { sourceId, useLiveData } = React.useContext(AnalysisContext)
  const logEvent = useLogEvent()

  const onPlaySnippets = React.useCallback((): void => {
    onNavigate?.(topicIndex, undefined, true)
    logEvent('megadrawer--play-all-conversations')
  }, [onNavigate, topicIndex, logEvent])

  const onPlayPhrase = React.useCallback(
    (phraseIndex: number) => {
      onNavigate?.(topicIndex, phraseIndex, true)
      logEvent('megadrawer--play-phrase-conversations')
    },
    [onNavigate, topicIndex, logEvent],
  )

  const { periodDef } = usePeriodContext()

  const requestOptions = {
    useLiveData,
    role,
    period: periodDef.name,
  }

  const { topics } = useTopics(sourceId, requestOptions, {
    dedupingInterval: 60000,
  })

  const { trendsReportTopicDetail } = useTrendTopicDetails(
    sourceId,
    topicIndex,
    requestOptions,
  )

  // Derive selected topic from `topicIndex` in route path
  const selectedTopic = React.useMemo<Topic | undefined>(() => {
    if (!topics || topics.length === 0) return
    return topics.find((x) => x.index === topicIndex)
  }, [topics, topicIndex])

  if (selectedTopic === undefined) {
    return null
  }

  return (
    <>
      <ChakraDrawer
        isOpen
        onClose={onClose}
        placement="right"
        size="xl"
        returnFocusOnClose={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottom="1px"
            borderBottomColor="gray.200"
            lineHeight={1.4}
            display="flex"
            alignItems="center"
            pr={10}
          >
            <PageHeading>
              <Text>{capitalize(selectedTopic?.name)}</Text>
            </PageHeading>
          </DrawerHeader>
          <DrawerBody overflow="auto" p={0}>
            <Flex width="100%" px={6} mx="auto">
              {trendsReportTopicDetail != null && (
                <TopicDetailsGrid
                  segmentUid={sourceId}
                  topicIndex={topicIndex}
                  topicDetails={trendsReportTopicDetail}
                  onPlaySnippets={onPlaySnippets}
                  onPlayPhrase={onPlayPhrase}
                />
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </ChakraDrawer>

      <Routes>
        <Route path="/conversations" element={<PlayTrendsSnippetsRouter />} />
      </Routes>
    </>
  )
}

export default function DrawerContainerWrapper(
  props: TrendMegaDrawerContainerProps,
): React.ReactElement {
  return <TrendMegaDrawerContainer {...props} />
}
