import { useParams, useSearchParams } from 'react-router-dom'

import { AudioProvider } from '@capturi/audio'
import { ErrorBoundary } from '@capturi/react-utils'
import React from 'react'
import AnalysisContext from '../contexts/AnalysisContext'
import { LiveTrendsProvider } from '../contexts/LiveTrendsContext'
import useTrendsNavigation from '../hooks/useTrendsNavigation'
import useTrendsRoutes from '../routes'
import PlaySnippetDrawerContainer from './PlaySnippetDrawerContainer'
import TrendMegaDrawerContainer from './TrendMegaDrawerContainer'

const SharedProviders: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <AudioProvider>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>{children}</React.Suspense>
    </ErrorBoundary>
  </AudioProvider>
)

function parseIndexValue(strValue?: string | null): number | null {
  if (strValue == null) return null
  const value = Number.parseInt(strValue, 10)
  if (!Number.isSafeInteger(value)) {
    return null
  }
  return value
}

export const PlaySegmentSnippetsRouter: React.FC<{
  onNavigate: (options?: {
    topicIndex?: number
    phraseIndex?: number
    trends?: boolean
    conversations?: boolean
  }) => void
}> = ({ onNavigate }) => {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const topicIndex = parseIndexValue(params.topicIndex)
  const phraseIndex = parseIndexValue(searchParams.get('phraseIndex'))

  const handleNavigate = React.useCallback(
    (topicIndex: number, phraseIndex?: number) => {
      onNavigate({
        topicIndex,
        phraseIndex,
        trends: false,
        conversations: true,
      })
    },
    [onNavigate],
  )

  if (topicIndex == null) return null
  return (
    <SharedProviders>
      <PlaySnippetDrawerContainer
        topicIndex={topicIndex}
        phraseIndex={phraseIndex}
        showExamples={false}
        onNavigate={handleNavigate}
        onClose={onNavigate}
      />
    </SharedProviders>
  )
}

export const PlayTrendsSnippetsRouter: React.FC = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const { useLiveData } = React.useContext(AnalysisContext)

  const topicIndex = parseIndexValue(params.topicIndex)
  const phraseIndex = parseIndexValue(searchParams.get('phraseIndex'))

  const trendsRoutes = useTrendsRoutes()
  const navigate = useTrendsNavigation(
    useLiveData ? trendsRoutes.liveTrends : trendsRoutes.segmentTrends,
  )

  const onNavigate = React.useCallback(
    (topicIndex: number, phraseIndex?: number) => {
      navigate({ topicIndex, phraseIndex, trends: true, conversations: true })
    },
    [navigate],
  )

  const onClose = React.useCallback(() => {
    navigate({ topicIndex: topicIndex ?? undefined, trends: true })
  }, [navigate, topicIndex])

  if (topicIndex == null) return null
  return (
    <SharedProviders>
      <PlaySnippetDrawerContainer
        topicIndex={topicIndex}
        phraseIndex={phraseIndex}
        showExamples={false}
        onNavigate={onNavigate}
        onClose={onClose}
      />
    </SharedProviders>
  )
}

export const PlayLiveSnippetsRouter: React.FC<{
  topicIndex?: number
  isOpen: boolean
  onClose: () => void
}> = ({ topicIndex, isOpen, onClose }) => {
  const [phraseIndex, setPhraseIndex] = React.useState<number | undefined>()

  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  React.useEffect(() => {
    setPhraseIndex(undefined)
  }, [topicIndex])

  const handleNavigate = React.useCallback(
    (_topicIndex: number, phraseIndex?: number) => {
      setPhraseIndex(phraseIndex)
    },
    [],
  )
  if (!isOpen || topicIndex == null) return null
  return (
    <SharedProviders>
      <PlaySnippetDrawerContainer
        topicIndex={topicIndex}
        phraseIndex={phraseIndex}
        showExamples={false}
        onNavigate={handleNavigate}
        onClose={onClose}
      />
    </SharedProviders>
  )
}

export const TrendMegaDrawerRouter: React.FC = () => {
  const params = useParams()
  const topicIndex = parseIndexValue(params.topicIndex)

  const { useLiveData } = React.useContext(AnalysisContext)
  const trendsRoutes = useTrendsRoutes()
  const navigate = useTrendsNavigation(
    useLiveData ? trendsRoutes.liveTrends : trendsRoutes.segmentTrends,
  )

  const onNavigate = React.useCallback(
    (topicIndex?: number, phraseIndex?: number, conversations?: boolean) => {
      navigate({
        topicIndex,
        phraseIndex,
        trends: true,
        conversations: conversations,
      })
    },
    [navigate],
  )

  const onClose = React.useCallback(() => {
    navigate()
  }, [navigate])

  if (topicIndex == null) return null
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <LiveTrendsProvider>
          <TrendMegaDrawerContainer
            topicIndex={topicIndex}
            onNavigate={onNavigate}
            onClose={onClose}
          />
        </LiveTrendsProvider>
      </React.Suspense>
    </ErrorBoundary>
  )
}
