import { TopicConversationsResponseModel } from 'features/aiTopics/model/api'
import { Role, TopicConversation } from 'features/aiTopics/model/domain'
import { useCallback, useMemo } from 'react'

import request from '@capturi/request'
import { useInfiniteQuery } from '@tanstack/react-query'
import { trendsAPI } from './api'

type Options = {
  useLiveData?: boolean
  role?: Role
  period?: string
  pageSize?: number
}

type UseTopicConversation = (
  sourceId: string | undefined,
  topicIndex: number,
  phraseIndex: number | undefined,
  options?: Options,
) => {
  pages: TopicConversation[][]
  isLoading: boolean
  fetchNextPage: () => Promise<void>
  refetch: () => Promise<void>
  hasNextPage?: boolean
  isFetchingNextPage: boolean
}

const useTopicConversationsEndpoint = (
  sourceId: string | undefined,
  topicIndex: number,
  phraseIndex: number | undefined,
  options: Options,
) => {
  const { useLiveData, role, period } = options

  if (sourceId == null) {
    return null
  }

  if (useLiveData) {
    if (phraseIndex != null) {
      return trendsAPI.getLiveTrendPhraseConversations(
        sourceId,
        topicIndex,
        phraseIndex,
      )
    }
    return trendsAPI.getLiveTrendConversations(sourceId, topicIndex)
  }
  if (!role || period == null) {
    return null
  }
  if (phraseIndex != null) {
    return trendsAPI.getTrendPhraseConversations(
      sourceId,
      topicIndex,
      phraseIndex,
      role,
      period,
    )
  }
  return trendsAPI.getTrendConversations(sourceId, topicIndex, role, period)
}

export const useTopicConversations: UseTopicConversation = (
  sourceId,
  topicIndex,
  phraseIndex,
  options = {},
) => {
  const { pageSize = 30 } = options
  const endpoint = useTopicConversationsEndpoint(
    sourceId,
    topicIndex,
    phraseIndex,
    options,
  )
  const {
    data,
    error,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'topicConversations',
      sourceId,
      topicIndex,
      phraseIndex,
      endpoint,
    ],
    queryFn: ({ pageParam }) =>
      endpoint
        ? request<TopicConversationsResponseModel>({
            ...endpoint,
            query: { ...endpoint.query, ...pageParam },
            searchParams: undefined,
          })
        : null,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage && lastPage.conversations.length < pageSize) {
        return undefined
      }

      return {
        pageNumber: pages.length,
        pageSize,
        sortDirection: 1,
      }
    },
    initialPageParam: {
      pageSize: pageSize,
      sortDirection: 1,
      pageNumber: 0,
    },
    enabled: endpoint !== null,
    refetchOnWindowFocus: false,
  })

  const isLoadingInitialData = !(data || error)

  const refetchCallback = useCallback(async (): Promise<void> => {
    await refetch()
  }, [refetch])

  const fetchNextPageCallback = useCallback(async (): Promise<void> => {
    await fetchNextPage()
  }, [fetchNextPage])

  return useMemo(() => {
    return {
      pages: data?.pages.map((p) => p?.conversations ?? []) ?? [],
      isLoading: isLoadingInitialData,
      fetchNextPage: fetchNextPageCallback,
      refetch: refetchCallback,
      hasNextPage,
      isFetchingNextPage,
    }
  }, [
    data,
    isLoadingInitialData,
    fetchNextPageCallback,
    refetchCallback,
    hasNextPage,
    isFetchingNextPage,
  ])
}
