import analytics, { EventParams } from '@capturi/analytics'
import { PeriodDefinition } from '@capturi/filters'
import { Role } from 'features/aiTopics'
import React, { useCallback } from 'react'

import AnalysisContext from './contexts/AnalysisContext'
import { usePeriodContext } from './contexts/PeriodContext'
import { useRoleContext } from './contexts/RoleContext'
import TrendTypeContext from './contexts/TrendTypeContext'

export type Event =
  | 'trend-type--live-selected' // selected Live Trends in TrendTypePickerPage
  | 'trend-type--segment-selected' // selected Segment Trends in TrendTypePickerPage
  | 'trend-type--ai-fake-door--modal-opened' // selected AI trends in TrendTypePickerPage
  | 'trend-type--ai-fake-door--interested' // clicked "Sounds promising" trends modal in TrendTypePickerPage
  | 'trend-type--ai-fake-door--not-interested' // clicked "Nope I'm good" in AI in AI trends modal in TrendTypePickerPage
  | 'role--changed' // Role has been changed
  | 'date-period--changed' // New Date selected in date picker
  | 'filter--saved' // Segment filter has been saved
  | 'filter--loaded' // Segment filter has been selected
  | 'filter--cleared' // Segment filter has been cleared
  | 'word-cloud-type--selected' // Word cloud type changed (trends/conversations)
  | 'no-content--appeared' // No content view appeared
  | 'view-topic-phrase-conversations' // Topic phrase converstations mini drawer opened
  | 'play-phrase-audio--clicked' // Topic phrase audio play clicked
  | 'trends-on-trends--opened' // Megadrawer trends on trends opened
  | 'megadrawer--play-all-conversations' // Megadrawer: play all converstations clicked
  | 'megadrawer--play-phrase-conversations' // Megadrawer: play phrase conversations clicked
  | 'megadrawer-trends-on-trends-list--play-conversations' // Megadrawer: trends-on-trends trend selected and converstaions opened
  | 'megadrawer--select-wordcloudtype' // Megadrawer: Word cloud type changed (trends/conversations)

export function logEvent(event: Event, params?: EventParams): void {
  analytics.event(`trends_v2__${event}`, params)
}

interface Logger {
  (event: Event, role?: Role): void
  (event: Event, periodDef: PeriodDefinition): void
  (event: Event, params?: EventParams): void
}

export function useLogEvent(): Logger {
  const [contextRole] = useRoleContext()
  const { periodDef: contextPeriodDef } = usePeriodContext()
  const trendType = React.useContext(TrendTypeContext)
  const { useLiveData } = React.useContext(AnalysisContext)
  const logger: Logger = useCallback(
    //
    (event: Event, ...restArgs: (undefined | string | EventParams)[]) => {
      let role = contextRole
      let periodDef = contextPeriodDef
      let eventParams: EventParams = {}
      restArgs.forEach((arg) => {
        if (typeof arg === 'string') {
          role = arg as Role
        } else if (arg instanceof PeriodDefinition) {
          periodDef = arg
        } else if (arg instanceof Object) {
          eventParams = arg as EventParams
        }
      })
      logEvent(event, {
        role,
        period: periodDef?.name,
        trendType: trendType,
        useLiveData,
        ...eventParams,
      })
    },
    [contextRole, contextPeriodDef, trendType, useLiveData],
  )
  return logger
}
