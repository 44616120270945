import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { ContentPlaceholder } from '@capturi/ui-components'
import { Trans } from '@lingui/macro'
import React from 'react'
import { useEffectOnce } from 'react-use'

import { useLogEvent } from '../events'

const NoContent: React.FC<{ title: string }> = ({ title }) => {
  const logEvent = useLogEvent()

  useEffectOnce(() => {
    logEvent('no-content--appeared')
  })

  return (
    <ContentPlaceholder.Container mt={20}>
      <ContentPlaceholder.Image as={Icon_EmptyState} />
      <ContentPlaceholder.Heading>{title}</ContentPlaceholder.Heading>
      <ContentPlaceholder.Body>
        <Trans>Try to select another &apos;Segment&apos;</Trans>
      </ContentPlaceholder.Body>
    </ContentPlaceholder.Container>
  )
}

export default NoContent
