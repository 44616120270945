import { Text } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import React from 'react'

import { pctFormat } from '../../analytics/shared/utils'

const TrendNumber: React.FC<{ value: number }> = ({ value }) => {
  let color = 'inherit'
  let sign = '' // NumberFormatOptions.signDisplay not widely supported
  if (value < 0) {
    color = 'danger'
  } else if (value > 0) {
    color = 'success'
    sign = '+'
  }
  return (
    <Text color={color}>
      {sign}
      {i18n.number(value, pctFormat)}
    </Text>
  )
}

export default TrendNumber
