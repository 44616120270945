import { Period } from '@capturi/filters'
import { plural, t } from '@lingui/macro'

const getValueAndUnit = (period: Period): string => {
  const hours = period.hours()
  const days = period.days()
  if (hours <= 24) {
    return plural(hours, {
      one: '# hour',
      other: '# hours',
    })
  }
  return t`${days} days`
}

export const getHitratePercentageTooltip = (period: Period): string => {
  const value = getValueAndUnit(period)
  return t`The percentage of all conversations, over the last ${value} where the topic was recognized.`
}

export const getTrendPercentageDifferenceTooltip = (period: Period): string => {
  const value = getValueAndUnit(period)
  return t`The percentage difference between the last ${value} and the previous ${value}.`
}
