import { Role } from 'features/aiTopics/model/domain'
import { useCallback } from 'react'

import { trendsAPI } from './api'
import { LiveTrendsResponse } from './types'
import useLiveTrendsWithRequest from './useLiveTrendsWithRequest'

type UseTrendsOnTrends = (useLiveData: boolean) => {
  fetch: (
    sourceId: string,
    topicIndex: number | undefined,
    sourceRole: Role,
    targetRole: Role,
    period: string,
    fromInclusive: Date,
    toInclusive: Date,
  ) => Promise<void>
  data: LiveTrendsResponse | undefined
  isLoading: boolean
  error: Error | undefined
}

const useTrendsOnTrends: UseTrendsOnTrends = (useLiveData) => {
  const { fetchWithRequestOptions, data, isLoading, error } =
    useLiveTrendsWithRequest()

  const fetch = useCallback(
    async (
      sourceId: string,
      topicIndex: number | undefined,
      sourceRole: Role,
      targetRole: Role,
      period: string,
      fromInclusive: Date,
      toInclusive: Date,
    ): Promise<void> => {
      if (topicIndex === undefined) {
        return
      }

      const request = useLiveData
        ? trendsAPI.calculateLiveTrendsOnTrendsForTopic(
            sourceId,
            topicIndex,
            targetRole,
            fromInclusive.toISOString(),
            toInclusive.toISOString(),
          )
        : trendsAPI.calculateReportTrendsOnTrendsForTopic(
            sourceId,
            topicIndex,
            sourceRole,
            targetRole,
            period,
            fromInclusive.toISOString(),
            toInclusive.toISOString(),
          )
      return await fetchWithRequestOptions(request)
    },
    [fetchWithRequestOptions, useLiveData],
  )
  return {
    fetch,
    data,
    isLoading,
    error,
  }
}

export default useTrendsOnTrends
