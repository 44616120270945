import { RequestOptions } from '@capturi/request'
import { Role } from 'features/aiTopics/model/domain'

const DEFAULT_API_VERSION = '3.3'

const controller = 'trends'
const baseQuery = {
  'api-version': DEFAULT_API_VERSION,
}

export const trendsAPI = {
  getSegments: (): RequestOptions => ({
    url: `${controller}/reports`,
    query: baseQuery,
  }),
  getSegment: (uid: string, role: Role, period: string): RequestOptions => ({
    url: `${controller}/reports/${uid}`,
    query: {
      ...baseQuery,
      role,
      period,
    },
  }),
  getTrendPhrases: (
    segmentUid: string,
    topicIndex: number,
    role: Role,
    period: string,
  ): RequestOptions => ({
    url: `${controller}/reports/${segmentUid}/topics/${topicIndex}`,
    query: {
      ...baseQuery,
      role,
      period,
    },
  }),
  getTrendConversations: (
    segmentUid: string,
    topicIndex: number,
    role: Role,
    period: string,
  ): RequestOptions => ({
    url: `${controller}/reports/${segmentUid}/topics/${topicIndex}/conversations`,
    query: {
      ...baseQuery,
      role,
      period,
    },
  }),
  getTrendPhraseConversations: (
    segmentUid: string,
    topicIndex: number,
    phraseIndex: number,
    role: Role,
    period: string,
  ): RequestOptions => ({
    url: `${controller}/reports/${segmentUid}/topics/${topicIndex}/phrases/${phraseIndex}/conversations`,
    query: {
      ...baseQuery,
      role,
      period,
    },
  }),
  getTrendsReportTopicDetails: (
    segmentUid: string,
    topicIndex: number,
    role: Role,
    period: string,
  ): RequestOptions => ({
    url: `${controller}/reports/${segmentUid}/topics/${topicIndex}/details`,
    query: {
      ...baseQuery,
      role,
      period,
    },
  }),
  calculateReportTrendsOnTrendsForTopic: (
    segmentUid: string,
    topicIndex: number,
    sourceRole: Role,
    targetRole: Role,
    period: string,
    fromInclusive: string,
    toInclusive: string,
  ): RequestOptions => {
    return {
      url: `${controller}/reports/${segmentUid}/topics/${topicIndex}/trends`,
      query: {
        ...baseQuery,
        sourceRole,
        targetRole,
        period,
        fromInclusive,
        toInclusive,
      },
      method: 'post',
    }
  },
  calculateLiveTrends: (
    role: Role,
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    filterModel: { [key: string]: any },
  ): RequestOptions => ({
    url: `${controller}/live`,
    query: {
      ...baseQuery,
      role,
    },
    method: 'post',
    json: filterModel,
  }),
  getLiveTrends: (liveTrendsUid: string): RequestOptions => ({
    url: `${controller}/live/${liveTrendsUid}`,
    query: baseQuery,
  }),
  getLiveTrendPhrases: (
    liveTrendsUid: string,
    topicIndex: number,
  ): RequestOptions => ({
    url: `${controller}/live/${liveTrendsUid}/topics/${topicIndex}`,
    query: baseQuery,
  }),
  getLiveTrendConversations: (
    liveTrendsUid: string,
    topicIndex: number,
  ): RequestOptions => ({
    url: `${controller}/live/${liveTrendsUid}/topics/${topicIndex}/conversations`,
    query: baseQuery,
  }),
  getLiveTrendPhraseConversations: (
    liveTrendsUid: string,
    topicIndex: number,
    phraseIndex: number,
  ): RequestOptions => ({
    url: `${controller}/live/${liveTrendsUid}/topics/${topicIndex}/phrases/${phraseIndex}/conversations`,
    query: baseQuery,
  }),
  getLiveTrendTopicDetails: (
    liveTopicsUid: string,
    topicIndex: number,
  ): RequestOptions => ({
    url: `${controller}/live/${liveTopicsUid}/topics/${topicIndex}/details`,
    query: {
      ...baseQuery,
    },
  }),
  calculateLiveTrendsOnTrendsForTopic: (
    liveTopicsUid: string,
    topicIndex: number,
    targetRole: Role,
    fromInclusive: string,
    toInclusive: string,
  ): RequestOptions => {
    return {
      url: `${controller}/live/${liveTopicsUid}/topics/${topicIndex}/trends`,
      query: {
        ...baseQuery,
        targetRole,
        fromInclusive,
        toInclusive,
      },
      method: 'post',
    }
  },
}
