import { useOrganization } from '@capturi/stores'
import {
  ListSkeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@capturi/ui-components'
import { Box } from '@chakra-ui/react'
import { select, t } from '@lingui/macro'
import { Role, Topic, TopicPhrase } from 'features/aiTopics'
import React from 'react'

import { useRoleContext } from '../contexts/RoleContext'
import { useLogEvent } from '../events'
import DataTable from './DataTableTempAd'
import NoContent from './NoContent'
import TopicsWordCloud, { TopicsWordCloudType } from './TopicsWordCloud'

const useRoles = (): {
  value: Role
  label: string
}[] => {
  const { organizationType } = useOrganization()
  return React.useMemo(() => {
    return [
      {
        value: 'All',
        label: t`All speakers`,
      },
      {
        value: 'Customer',
        label: select(organizationType, {
          public: 'Citizen',
          other: 'Customer',
        }),
      },
      {
        value: 'Agent',
        label: t`Employee`,
      },
    ]
  }, [organizationType])
}

const TrendsDataTabs: React.FC<{
  isLoading: boolean
  topics: Topic[]
  renderResults?: boolean
  onSelectTopic?: (
    topic: Topic,
    phrase?: TopicPhrase,
    sortId?: string,
    sortDesc?: boolean,
  ) => void
  onPlaySnippets?: (topic: Topic) => void
  children?: React.ReactNode
}> = ({
  isLoading,
  topics,
  renderResults = true,
  onSelectTopic,
  onPlaySnippets,
  children,
}) => {
  const roles = useRoles()
  const [role, setRole] = useRoleContext()
  const logEvent = useLogEvent()

  const tabsIndex = React.useMemo(() => {
    return roles.findIndex((x) => x.value === role) ?? 0
  }, [role, roles])

  const handleTabsChange = React.useCallback(
    (idx: number): void => {
      const role = roles[idx].value
      setRole(role)
      logEvent('role--changed', role)
    },
    [logEvent, roles, setRole],
  )

  const handleChangeWordCloudType = React.useCallback(
    (type: TopicsWordCloudType) => {
      logEvent('word-cloud-type--selected', {
        wordCloudType: type,
      })
    },
    [logEvent],
  )

  return (
    <Tabs index={tabsIndex} onChange={handleTabsChange} isLazy>
      <TabList>
        {roles.map((role) => (
          <Tab key={role.value}>{role.label}</Tab>
        ))}
      </TabList>
      <Box my={4}>{children}</Box>
      <TabPanels>
        {roles.map((x) => (
          <TabPanel key={x.value} p={0}>
            {x.value === role && (
              <>
                {renderResults && (
                  <ListSkeleton isLoaded={!isLoading}>
                    {topics.length > 0 ? (
                      <>
                        <TopicsWordCloud
                          topics={topics}
                          onChangeTopicsWordCloudType={
                            handleChangeWordCloudType
                          }
                          onSelectTopic={onSelectTopic}
                        />
                        <DataTable
                          topics={topics}
                          onSelectTopic={onSelectTopic}
                          onPlaySnippets={onPlaySnippets}
                        />
                      </>
                    ) : (
                      <NoContent title={t`No results`} />
                    )}
                  </ListSkeleton>
                )}
              </>
            )}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

export default TrendsDataTabs
