import {
  Card,
  CardContent,
  CardHeader,
  CardMeta,
  PlaySnippetsButton,
} from '@capturi/ui-components'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Tag,
  Text,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { pctFormat } from 'pages/analytics/shared/utils'
import React from 'react'
import { RiArrowDropDownFill, RiArrowDropUpFill } from 'react-icons/ri'

import { TrendsOnTrendsContainer } from '../components/TrendsOnTrendsContainer'
import AnalysisContext from '../contexts/AnalysisContext'
import { TrendsOnTrendsProvider } from '../contexts/TrendsOnTrendsContext'
import { TrendsReportTopicDetailResponse } from '../data/types'

const ShareOfConversationsCard: React.FC<{
  topicDetails: TrendsReportTopicDetailResponse
}> = ({ topicDetails }) => {
  return (
    <Box>
      <Card pos="relative">
        <CardContent>
          <CardHeader>
            <Trans>Share of conversations</Trans>
          </CardHeader>
          <CardMeta>
            <Trans>Relative to all conversations</Trans>
          </CardMeta>

          <Flex
            minH={16}
            align="baseline"
            alignItems="center"
            justify="bottom"
            wrap="nowrap"
          >
            <Text fontSize="5xl" color="gray.800" fontWeight={500}>
              {i18n.number(topicDetails.hitrate, pctFormat)}
            </Text>
            <Flex
              align="baseline"
              justify="bottom"
              color={topicDetails.trend >= 0 ? 'success' : 'danger'}
            >
              <Box transform="translateY(25%)" mr={-1}>
                {topicDetails.trend >= 0 ? (
                  <RiArrowDropUpFill size="1.7em" />
                ) : (
                  <RiArrowDropDownFill size="1.7em" />
                )}
              </Box>
              <Text fontSize="lg">
                {i18n.number(Math.abs(topicDetails.trend), pctFormat)}
              </Text>
            </Flex>
          </Flex>
          <StackDivider
            gridArea="divider"
            borderColor="gray.200"
            borderWidth="1px"
          />
          <Text fontSize="md" color="gray.600" mt={3}>
            <Trans>{topicDetails.hits} conversations</Trans>
          </Text>
        </CardContent>
      </Card>
    </Box>
  )
}

const SubjectTopicsCard: React.FC<{
  topicDetails: TrendsReportTopicDetailResponse
  onPlaySnippets?: () => void
  onPlayPhrase?: (phraseIndex: number) => void
}> = ({ topicDetails, onPlaySnippets, onPlayPhrase }) => {
  return (
    <Card pos="relative" h="100%">
      <CardContent>
        <CardHeader>
          <HStack>
            <Trans>Subject consist of</Trans>
            <Spacer />
            <PlaySnippetsButton
              label={t`View conversations`}
              onClick={(e) => {
                e.stopPropagation()
                onPlaySnippets?.()
              }}
            />
          </HStack>
        </CardHeader>
        <Flex
          align="baseline"
          alignItems="center"
          justify="flex-start"
          flexWrap="wrap"
          mt={4}
        >
          {topicDetails.phrases?.map((value, i) => (
            <Tag
              key={i}
              ml="1"
              mb="1"
              _hover={{ bg: 'gray.300', cursor: 'pointer' }}
              onClick={() => onPlayPhrase?.(i)}
            >
              {value.name} (
              {i18n.number(value.hitrate, {
                style: 'percent',
              })}
              )
            </Tag>
          ))}
        </Flex>
      </CardContent>
    </Card>
  )
}

const TopicDetailsGrid: React.FC<{
  segmentUid: string | undefined
  topicIndex: number
  topicDetails: TrendsReportTopicDetailResponse
  onPlaySnippets?: () => void
  onPlayPhrase?: (phraseIndex: number) => void
}> = ({
  segmentUid,
  topicIndex,
  topicDetails,
  onPlaySnippets,
  onPlayPhrase,
}) => {
  const { useLiveData } = React.useContext(AnalysisContext)
  return (
    <Stack spacing={4} mt={4} w="100%">
      <Grid
        flex={1}
        templateColumns={{
          md: '2fr 3fr',
        }}
        columnGap={4}
      >
        <GridItem>
          <ShareOfConversationsCard topicDetails={topicDetails} />
        </GridItem>
        <GridItem>
          <SubjectTopicsCard
            topicDetails={topicDetails}
            onPlaySnippets={() => onPlaySnippets?.()}
            onPlayPhrase={(i) => onPlayPhrase?.(i)}
          />
        </GridItem>
      </Grid>
      <Box pt={5} pb={5}>
        <StackDivider
          gridArea="divider"
          borderColor="gray.200"
          borderWidth="1px"
        />
      </Box>
      <Grid
        templateColumns={{ md: 'auto auto' }}
        gap="4rem"
        alignItems="top"
        mb={8}
      >
        <Box>
          <Heading fontSize="xl">
            <Trans>Subjects within &ldquo;{topicDetails.name}&rdquo;</Trans>
          </Heading>
          <Text color="textMuted" fontSize="sm">
            <Trans>Trend calculation for up to 400 conversations</Trans>
          </Text>
        </Box>
        {topicDetails.hits > 400 && (
          <HStack spacing={4} justify="flex-end" alignItems="top" as="i">
            <Text
              color="warning"
              fontSize="sm"
              align="right"
              fontStyle="italic"
            >
              <Trans>
                NB! The period and the filtration consists of more than 400
                conversations. We have made a calculation on the 400 newest
                conversations within the timeframe.
              </Trans>
            </Text>
          </HStack>
        )}
      </Grid>
      {segmentUid && (
        <TrendsOnTrendsProvider useLiveData={useLiveData}>
          <TrendsOnTrendsContainer
            segmentUid={segmentUid}
            topicIndex={topicIndex}
          />
        </TrendsOnTrendsProvider>
      )}
    </Stack>
  )
}

export default TopicDetailsGrid
