import { Button } from '@capturi/ui-components'
import {
  OnChangeValue,
  OptionProps,
  PopoutSelect,
  SelectOptionBase,
  components,
} from '@capturi/ui-select'
import {
  Box,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { TopicPhrase } from 'features/aiTopics'
import React from 'react'
import { MdArrowDropDown } from 'react-icons/md'

import { unformatPhrase } from '../utils'

type PhraseOption = SelectOptionBase & {
  phrase?: TopicPhrase | null
}

function Option(props: OptionProps<PhraseOption, false>): React.ReactElement {
  const { label, phrase } = props.data as PhraseOption
  return (
    <components.Option {...props}>
      <Box noOfLines={1} wordBreak="break-all">
        {label}
      </Box>
      {phrase != null && (
        <Text
          fontSize="xs"
          color="textMuted"
          noOfLines={1}
          wordBreak="break-all"
        >
          <Trans>Ex.</Trans>: <em>{unformatPhrase(phrase?.displayName)}</em>
        </Text>
      )}
    </components.Option>
  )
}

const TopicPhrasePopoverSelect: React.FC<{
  phrases: TopicPhrase[]
  phrase?: TopicPhrase
  onPhraseChange: (phrase: TopicPhrase | undefined) => void
  children?: React.ReactNode
}> = ({ phrases, phrase, onPhraseChange, children }) => {
  const { options, value } = React.useMemo(() => {
    const options: PhraseOption[] = [
      {
        value: 'all',
        label: t`All variants`,
        phrase: null,
      },
      ...phrases.map((x) => ({
        value: x.name,
        label: x.name,
        phrase: x,
      })),
    ]

    let value: PhraseOption | undefined = undefined
    if (phrase) {
      value = {
        value: phrase.name,
        label: phrase.name,
        phrase: phrase,
      }
    }
    return {
      options,
      value,
    }
  }, [phrases, phrase])

  const handleChange = (option: OnChangeValue<PhraseOption, false>): void => {
    if (option == null) {
      onPhraseChange(undefined)
      return
    }
    onPhraseChange(option.phrase ?? undefined)
  }

  return (
    <Popover placement="auto-start">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              variant="ghost"
              px="8px"
              ml="-8px"
              height="fit-content"
              rightIcon={<Icon as={MdArrowDropDown} boxSize="20px" />}
            >
              {children}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoutSelect
              placeholder={t`Search for a phrase ...`}
              options={options}
              value={value}
              onChange={(value) => {
                handleChange(value)
                onClose?.()
              }}
              components={{
                Option,
              }}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export default TopicPhrasePopoverSelect
