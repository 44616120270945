import { useAPI } from '@capturi/api-utils'
import { Role, Topic } from 'features/aiTopics/model/domain'
import { useMemo } from 'react'
import { SWRConfiguration } from 'swr'

import { trendsAPI } from './api'

type Options = {
  useLiveData?: boolean
  role?: Role
  period?: string
}

type UseTopics = (
  sourceId?: string,
  options?: Options,
  swrConfig?: SWRConfiguration,
) => {
  topics: Topic[]
  isLoading: boolean
}

const useTopics: UseTopics = (sourceId, options = {}, swrConfig = {}) => {
  const { data, mutate, error } = useAPI<{ topics: Topic[] }>(
    () => {
      if (sourceId == null) {
        return null
      }
      const { useLiveData, role, period } = options
      if (useLiveData) {
        return trendsAPI.getLiveTrends(sourceId)
      }
      if (role && period != null) {
        return trendsAPI.getSegment(sourceId, role, period)
      }
      return null
    },
    {
      suspense: true,
      revalidateOnFocus: false,
      ...swrConfig,
    },
  )

  return useMemo(() => {
    const topics = (data?.topics ?? []).map<Topic>((x) => ({
      ...x,
      name: x.name.toLowerCase(),
    }))
    return {
      topics,
      isLoading: !(data || error),
      mutate,
    }
  }, [data, error, mutate])
}

export default useTopics
