import { useCallback, useMemo } from 'react'

import { trendsAPI } from './api'
import { LiveTrendsResponse } from './types'
import useLiveTrendsWithRequest from './useLiveTrendsWithRequest'

export type LiveTrendsResultsInfo = {
  isLimited: boolean
  conversationCount: number
  conversationsUsed: number
  analysisPercentage: number
}

export type UseLiveTrendsRoleData = {
  data: LiveTrendsResponse | undefined
  resultsInfo: LiveTrendsResultsInfo | undefined
  isLoading: boolean
  error: Error | undefined
}

export type UseLiveTrendsData =
  | {
      [roleName: string]: UseLiveTrendsRoleData
    }
  | undefined

function resultsInfoForLiveTrendsResponse(
  data: LiveTrendsResponse | undefined,
): LiveTrendsResultsInfo | undefined {
  if (data == null) {
    return {
      isLimited: false,
      conversationCount: 0,
      conversationsUsed: 0,
      analysisPercentage: 0,
    }
  }
  const requested = data.conversationsRequested
  return {
    isLimited: data.conversationsUsed < data.conversationsRequested,
    conversationCount: data.conversationsRequested,
    conversationsUsed: data.conversationsUsed,
    analysisPercentage:
      requested === 0
        ? 0
        : data.conversationsUsed / data.conversationsRequested,
  }
}

type UseLiveTrends = () => {
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  fetch: (filterModel?: { [key: string]: any }) => Promise<void>
  data: UseLiveTrendsData
}

const useLiveTrends: UseLiveTrends = () => {
  const {
    fetchWithRequestOptions: fetchAgent,
    data: dataAgent,
    isLoading: isLoadingAgent,
    error: errorAgent,
  } = useLiveTrendsWithRequest()

  const {
    fetchWithRequestOptions: fetchCustomer,
    data: dataCustomer,
    isLoading: isLoadingCustomer,
    error: errorCustomer,
  } = useLiveTrendsWithRequest()

  const {
    fetchWithRequestOptions: fetchAll,
    data: dataAll,
    isLoading: isLoadingAll,
    error: errorAll,
  } = useLiveTrendsWithRequest()

  const fetch = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    async (filterModel: { [key: string]: any } = {}): Promise<void> => {
      return await Promise.all([
        fetchAgent(trendsAPI.calculateLiveTrends('Agent', filterModel)),
        fetchCustomer(trendsAPI.calculateLiveTrends('Customer', filterModel)),
        fetchAll(trendsAPI.calculateLiveTrends('All', filterModel)),
      ]).then(() => {
        return
      })
    },
    [fetchAgent, fetchCustomer, fetchAll],
  )

  const data = useMemo(() => {
    return {
      Agent: {
        data: dataAgent,
        isLoading: isLoadingAgent,
        error: errorAgent,
        resultsInfo: resultsInfoForLiveTrendsResponse(dataAgent),
      },
      Customer: {
        data: dataCustomer,
        isLoading: isLoadingCustomer,
        error: errorCustomer,
        resultsInfo: resultsInfoForLiveTrendsResponse(dataCustomer),
      },
      All: {
        data: dataAll,
        isLoading: isLoadingAll,
        error: errorAll,
        resultsInfo: resultsInfoForLiveTrendsResponse(dataAll),
      },
    }
  }, [
    dataAgent,
    dataCustomer,
    dataAll,
    isLoadingAgent,
    isLoadingCustomer,
    isLoadingAll,
    errorAgent,
    errorCustomer,
    errorAll,
  ])

  return useMemo(() => {
    return {
      fetch,
      data,
    }
  }, [fetch, data])
}

export default useLiveTrends
