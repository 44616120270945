import constate from 'constate'
import { Role } from 'features/aiTopics/model/domain'
import React from 'react'

import { LiveTrendsResponse } from '../data/types'
import useLiveTrendsOnTrends from '../data/useTrendsOnTrends'

function useTrendsOnTrendsWithResultsInfo({
  useLiveData,
}: {
  useLiveData: boolean
}): {
  fetch: (
    sourceId: string,
    topicIndex: number | undefined,
    sourceRole: Role,
    targetRole: Role,
    period: string,
    fromInclusive: Date,
    toInclusive: Date,
  ) => Promise<void>
  data: LiveTrendsResponse | undefined
  isLoading: boolean
  error: Error | undefined
  resultsInfo: {
    isLimited: boolean
    conversationCount: number
    conversationsUsed: number
    analysisPercentage: number
  }
} {
  const { data, fetch, isLoading, error } = useLiveTrendsOnTrends(useLiveData)

  const resultsInfo = React.useMemo(() => {
    if (data == null) {
      return {
        isLimited: false,
        conversationCount: 0,
        conversationsUsed: 0,
        analysisPercentage: 0,
      }
    }
    const requested = data.conversationsRequested
    return {
      isLimited: data.conversationsUsed < data.conversationsRequested,
      conversationCount: data.conversationsRequested,
      conversationsUsed: data.conversationsUsed,
      analysisPercentage:
        requested === 0
          ? 0
          : data.conversationsUsed / data.conversationsRequested,
    }
  }, [data])

  return {
    fetch,
    data,
    isLoading,
    error,
    resultsInfo,
  }
}

const [TrendsOnTrendsProvider, useTrendsOnTrendsContext] = constate(
  useTrendsOnTrendsWithResultsInfo,
)

export { TrendsOnTrendsProvider, useTrendsOnTrendsContext }
